import clsx from 'clsx';
import {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';

import pjson from '../../../package.json';
import metaApp from '../../metadata.json';
import {LIST_MENU} from "./constant";
import axios from "../../api/api.config";
import {toast} from "react-toastify";
import {catchErrorMessage} from "../../ui-utils/string.utils";

export const SidebarComponent = () => {
    const location = useLocation();
    const {pathname} = location;
    const [menuItems, setMenuItems] = useState([...LIST_MENU]);
    const menuFromStorage = localStorage.getItem('menu')

    useEffect(() => {
        if (!!menuFromStorage) {
            let convertedData = JSON.parse(menuFromStorage);
            const mergedData = menuItems.map(itemA => {
                const matchingItem = convertedData.find(itemB => itemB.id === itemA.id);
                if (matchingItem) {
                    return {
                        ...itemA,
                        visible: matchingItem.visible,
                        children: itemA.children.map(childA => {
                            const matchingChildB = matchingItem.children.find(childB => childB.id === childA.id);
                            return {
                                ...childA,
                                visible: matchingChildB ? matchingChildB.visible : false
                            };
                        })
                    };
                }
            });

            const filteredData = mergedData
                .filter(item => item.visible)
                .map(item => ({
                    ...item,
                    children: item.children.filter(child => child.visible)
                }));

            setMenuItems(filteredData)
        }

        if (!menuFromStorage) getMenu()

    }, [menuFromStorage]);



    const getMenu = async () => {
        try {
            const menu = await axios.get(`api/v1/admin/menu`);
            await localStorage.setItem('menu', JSON.stringify(menu?.data.data))
        } catch (error) {
            toast.error(
                catchErrorMessage(
                    error?.response?.data?.message || error?.message || 'Something went wrong, please try again later.'
                ),
                {
                    position: toast.POSITION.TOP_CENTER,
                }
            );
        }
    }


    return (
        <div className='w-[256px]'>
            <div className='w-[256px] h-full fixed left-0 top-0 bottom-0 side-menu'>
                <Link to='/' className='logo'>
                    <img alt='Main Logo' src='/img/logo.svg' className='max-w-max inline-block text-center'/>
                    <p className='text-ellipsis text-xs mt-6 text-center font-medium overflow-hidden text-green  whitespace-nowrap '>
                        App Version: {pjson.version} ({metaApp.build_number})
                    </p>
                </Link>

                <div className='inner-menu scroll'>
                    {menuItems.map((item) => {
                        if (item.path != null && item.children.length === 0) {
                            return (
                                <Link
                                    to={item.path}
                                    key={item.id}
                                    className={clsx(
                                        pathname.includes(item.parentPath)
                                            ? 'cursor-pointer flex items-center py-4 px-8 text-white bg-green font-bold text-base transition duration-150 ease-in-out'
                                            : 'flex items-center py-4 text-green hover:text-white px-8 hover:bg-green hover:font-bold font-medium text-base transition duration-150 ease-in-out'
                                    )}
                                >
                                    <span className={clsx('text-lg mr-4', item.icon)}></span> {item.title}
                                </Link>
                            );
                        }
                        return (
                            <>
                <span
                    className={clsx(
                        pathname.includes(item.parentPath)
                            ? 'cursor-pointer flex items-center py-4 px-8 text-white bg-green font-bold text-base transition duration-150 ease-in-out'
                            : 'flex items-center py-4 text-green hover:text-white px-8 hover:bg-green hover:font-bold font-medium text-base transition duration-150 ease-in-out'
                    )}
                    data-bs-toggle='collapse'
                    role='button'
                    href={`#${item.id}`}
                    aria-expanded='true'
                >
                  <span className={clsx('text-lg mr-4', item.icon)}></span> {item.title}
                </span>
                                <div className={clsx('collapse', pathname.includes(item.parentPath) && 'show')}
                                     id={item.id}>
                                    <ul className='pl-0 mt-2 mb-3'>
                                        {item.children.map((child) => {
                                            return (
                                                <Link to={child.link}
                                                      className='block cursor-pointer relative text-left'
                                                      key={child.id}>
                          <span
                              className={clsx(
                                  'text-green px-8 py-2 block relative hover:menu-active hover:font-bold cursor-pointer  list-menu transition duration-150 ease-in-out text-base ',
                                  pathname === child.link && 'menu-active font-bold',
                                  pathname.includes(child.basePath) && 'menu-active font-bold'
                              )}
                          >
                            {child.title}
                          </span>
                                                </Link>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
