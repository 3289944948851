import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessToken: null,
  rememberMe: false,
  expiredDate: null,
  profile: {
    id: null,
    name: '',
    email: null,
    phone: null,
    address: null,
    profile_picture: null,
    role: {
      id: null,
      name: '',
      code: null,
    },
  },
};

export const authSlice = createSlice({
  name: '@AUTHENTICATION',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.rememberMe = action.payload.rememberMe;
      state.expiredDate = action.payload.expiredDate;
      state.refreshToken = action.payload.refreshToken;
    },
    setBasicProfile: (state, action) => {
      state.profile = action.payload;
    },
    logout: (state) => {
      const newState = {
        ...state,
        accessToken: null,
        rememberMe: false,
        expiredDate: null,
        refreshToken: null,
      };
      return newState;
    },
  },
});

export const { setBasicProfile, setAccessToken, logout } = authSlice.actions;

export default authSlice.reducer;
