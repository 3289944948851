import {MainDashboardLayoutComponent} from "../../../components/main-layout/main.component";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useMemo, useState} from "react";
import {useUserAdminList} from "../../../api/users/admin/admin.query.api";
import {BasicTableComponent} from "../../../components/table/table-basic.component";
import {catchErrorMessage} from "../../../ui-utils/string.utils";
import {EmptyStateComponent} from "../../../components/empty-data";
import {Tooltip} from "flowbite-react";
import {HighlightedText} from "../../../components/highlighted-text";
import {ModalConfirmation} from "../../../components/modal/moda.confirmation.component";
import {toast} from "react-toastify";
import {useDeleteUserAdminDetail} from "../../../api/users/admin/admin.mutation.api";
import {TableSearchInputComponent} from "../../../components/table/table-search.component";
import qs from "query-string";
import {TableButtonSortComponent} from "../../../components/table/table-button-sort.component";
import {TableButtonLimitComponent} from "../../../components/table/table-button-limit.component";
import {ModalTableLimitComponent} from "../../../components/modal/modal-table-limit.component";
import {Pagination} from "../../../components/table/table-pagination.component";
import {ModalTableSortComponent} from "../../../components/modal/modal-table-sort.component";

const BREAD_CRUMB_ITEMS = [
    {
        title: 'Users',
        path: '/users/admin',
    },
    {
        title: 'User Admin',
        path: '/users/admin',
    },
];

const SORT_OPTIONS = [
    { label: 'Urutkan Nama A-Z', sortType: 'asc', sortBy: 'name' },
    { label: 'Urutkan Nama Z-A', sortType: 'desc', sortBy: 'name' },
    { label: 'Tanggal Buat Terbaru - Terdahulu', sortType: 'desc', sortBy: 'created_at' },
    { label: 'Tanggal Buat Terdahulu - Terbaru', sortType: 'asc', sortBy: 'created_at' },
];

export const UserAdminComponent = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const INITIAL_VALUE_FILTER_QUERY = {
        limit: searchParams.get('limit')?.replace(/[^0-9]/g, '') || 20,
        page: searchParams.get('page')?.replace(/[^0-9]/g, '') || 1,
        orderBy: searchParams.get('orderBy')?.replace(/[^a-zA-Z_]/g, '') || 'created_at',
        orderType: searchParams.get('orderType')?.replace(/[^a-zA-Z]/g, '') || 'desc',
        search: searchParams.get('search')?.replace(/[^a-zA-Z0-9 ]/g, '') || undefined,
    };
    const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);
    const {data: adminList, isLoading, isError, error} = useUserAdminList({filter: queryFilter});
    const [isDelete, setIsDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const {mutate: deleteAdminById, isLoading: isDeleting} = useDeleteUserAdminDetail();
    const [modals, setModals] = useState({
        sort: false,
        limit: false,
        result: false,
    });

    const columns = useMemo(
        () => [
            {
                name: 'User',
                className: 'w-[30%] text-sm font-semibold text-green pl-10',
                dataIndex: 'user',
                render: (record) => (
                    <div className='w-[30%] text-grey-70 text-sm font-medium'>
                        <div class='flex items-center pl-2 xl:pl-5'>
                            <div class='w-12'>
                                <img
                                    src={record?.profile_picture || '/img/default-user.jpg'}
                                    class='w-12 h-12 object-cover'
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = '/img/default-user.jpg';
                                    }}
                                    alt='User'
                                />
                            </div>
                            <div class='w-auto pl-3 xl:pl-5'>
                                <Tooltip className='max-w-[40%]' content={record?.name}>
                                    <HighlightedText text={record?.name} highlight={queryFilter?.search}/>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                name: 'Email',
                className: 'w-[25%] text-sm font-semibold text-green',
                dataIndex: 'email',
                render: (record) => (
                    <div class='w-[25%]'>
                        <div class='text-sm font-medium text-gray'>{record?.email}</div>
                    </div>
                ),
            },
            {
                name: 'Role',
                className: 'w-[25%] text-sm font-semibold text-green',
                dataIndex: 'role',
                render: (record) => (
                    <div class='w-[25%]'>
                        <div class='text-sm font-medium text-gray'>{record?.role}</div>
                    </div>
                ),
            },
            {
                name: 'Action',
                className: 'w-[10%] text-sm font-semibold text-green',
                dataIndex: 'id',
                render: (record) => (
                    <div className={"w-[10%] flex flex-row items-center space-x-3 text-xs font-semibold justify-end"}>
                        <button className={"flex flex-row items-center space-x-2"}
                                onClick={() => onShowDeleteAlert(record)}>
                            <span className='icon-ico-delete text-red-1 text-lg'></span>
                            <p>Delete</p>
                        </button>
                        <div className={"w-[1px] h-5 bg-slate-300"}/>
                        <Link to={`/users/admin/update/${record?.id}`}>
                            <button className={"flex flex-row items-center space-x-2"}>
                                <span className='icon-ico-pencil-edit text-yellow text-lg'></span>
                                <p>Edit</p>
                            </button>
                        </Link>
                    </div>
                )
            }
        ],
        [queryFilter.search]
    );

    const onShowDeleteAlert = (item) => {
        setSelectedItem(item)
        setIsDelete(true);
    }

    const handleDelete = async () => {
        deleteAdminById(
            selectedItem.id,
            {
                onSuccess: () => {
                    setIsDelete(false);
                    setSelectedItem(null);
                    toast.success(`User Admin berhasil dihapus!`);
                },
            }
        );
    }

    return (
        <MainDashboardLayoutComponent breadCumbs={BREAD_CRUMB_ITEMS}>
            <div className='w-[calc(100%-340px)] flex items-center justify-between mb-5'>
                <TableSearchInputComponent
                    initialValue={INITIAL_VALUE_FILTER_QUERY.search}
                    inputProps={{
                        disabled: isLoading,
                    }}
                    onSearch={(value) => {
                        setQueryFilter({...queryFilter, search: value.keyword, page: 1});
                        navigate({
                            search: qs.stringify({
                                ...queryFilter,
                                search: value.keyword || undefined,
                                page: 1,
                            }),
                        });
                    }}
                />

                <div
                    className='xl:w-[calc(100%-35%)] lg:w-[calc(100%-30%)] md:w-[calc(100%-23%)] flex xl:justify-start lg:justify-between md:justify-between items-center'>
                    <TableButtonSortComponent
                        disabled={isLoading}
                        onClick={() => {
                            setModals((prev) => ({...prev, sort: true}));
                        }}
                    />
                    <TableButtonLimitComponent
                        disabled={isLoading}
                        value={queryFilter.limit}
                        onClick={() => setModals({...modals, limit: true})}
                    />
                </div>
            </div>
            <>
                <div
                    className='italic text-dark text-sm mb-5'>{`Total ${adminList?.data?.total_result} users`}</div>
                <BasicTableComponent
                    rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
                    columns={columns}
                    dataSource={adminList?.data?.rows || []}
                    loading={isLoading}
                    error={isError}
                    errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
                    errorMessage={catchErrorMessage(error?.response?.data?.message)}
                />

                {adminList?.data?.total_result === 0 && (
                    <EmptyStateComponent
                        message={queryFilter.search ? `No result for query "${queryFilter.search}"` : 'No data available'}
                    />
                )}
                <Pagination
                    currentPage={queryFilter.page}
                    pageSize={queryFilter.limit}
                    siblingCount={1}
                    totalCount={adminList?.data?.total_result || 0}
                    onPageChange={(page) => {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                        setQueryFilter({...queryFilter, page});
                        navigate({
                            search: qs.stringify({
                                ...queryFilter,
                                page,
                            }),
                        });
                    }}
                />
            </>
            <ModalTableSortComponent
                options={SORT_OPTIONS}
                initialValue={SORT_OPTIONS.find(
                    (sortItem) => sortItem.sortBy === queryFilter.orderBy && sortItem.sortType === queryFilter.orderType
                )}
                onChange={({ selected }) => {
                    setQueryFilter((prevState) => ({
                        ...prevState,
                        orderBy: selected.sortBy,
                        orderType: selected.sortType,
                    }));
                    navigate({
                        search: qs.stringify({
                            ...queryFilter,
                            orderBy: selected.sortBy,
                            orderType: selected.sortType,
                        }),
                    });
                    setModals({ ...modals, sort: false });
                }}
                visible={modals.sort}
                onClose={() => setModals({ ...modals, sort: false })}
            />
            <ModalTableLimitComponent
                onChange={({limit}) => {
                    setQueryFilter((prevState) => ({
                        ...prevState,
                        limit,
                        page: 1,
                    }));
                    navigate({
                        search: qs.stringify({
                            ...queryFilter,
                            limit,
                            page: 1,
                        }),
                    });
                    setModals({...modals, limit: false});
                }}
                initialValue={queryFilter.limit}
                visible={modals.limit}
                onClose={() => setModals({...modals, limit: false})}
            />
            <ModalConfirmation
                description='Apakah anda yakin ingin menghapus data yang anda pilih ?'
                title='Hapus Data'
                visible={isDelete}
                onConfirm={handleDelete}
                imageIcon='/img/Delete.png'
                onClose={() => setIsDelete(false)}
                textConfirm='Delete'
            />
        </MainDashboardLayoutComponent>
    )
}
