import * as Yup from 'yup';

/**
 * Form validation schema for /auth/login
 */

export const CreateUserAdminValidationSchema = Yup.object().shape({
    email: Yup.string().email('Email must be valid email').required('Email is required'),
    name: Yup.string().required('Name is required').max(80, 'Name is too long'),
    phone: Yup.string().test('phone', 'Phone number is not valid', (value) => {
        if (!value) return true;
        return value.length >= 7 && value.length <= 15;
    }),
    password: Yup.string().required('Password is required').min(6, 'Password at least 6 characters'),
    role_id: Yup.string().required('Role is required').nullable(),
});

export const EditUserAdminValidationSchema = Yup.object().shape({
    email: Yup.string().email('Email must be valid email').required('Email is required'),
    name: Yup.string().required('Name is required').max(80, 'Name is too long'),
    phone: Yup.string().test('phone', 'Phone number is not valid', (value) => {
        if (!value) return true;
        return value.length >= 7 && value.length <= 15;
    }),
    role_id: Yup.string().required('Role is required').nullable(),
});
