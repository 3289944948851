import React from "react";
import {MainDashboardLayoutComponent} from "../../components/main-layout/main.component";

export default function Home(){
    return(
        <MainDashboardLayoutComponent>
            <div className={'flex flex-col justify-center items-center h-[80vh] w-full'}>
                <img alt='Main Logo' src='/img/logo.svg' className='max-w-max inline-block text-center'/>
            </div>
        </MainDashboardLayoutComponent>
    )
}
