import './App.css';

import 'react-toastify/dist/ReactToastify.css';
import {useEffect, useState} from 'react';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {BrowserRouter} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ErrorBoundary} from 'react-error-boundary';
import GeneralErrorPage from 'pages/error/error-boundary.page';
import SplashScreenPage from 'pages/splash-screen';
import {ToastContainer, toast} from 'react-toastify';

import {catchErrorMessage} from 'ui-utils/string.utils';
import {persistor, store} from 'reducers';

import RoutePage from './routes';
import {logout, setAccessToken} from "./reducers/auth.reducer";
import axios, {resetAuthHeader, setAuthHeader} from "./api/api.config";

const defaultQueryConfig = {
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnMount: true,
    retry: false,
    refetchOnWindowFocus: false,
    onError: (error, variables) => {
        const errorData = error?.response?.data
        if (errorData.code === 401 && errorData.message === 'invalid-token') {
            getRefreshToken()
        } else if(errorData.code === 401 && errorData.message === 'session-expired') {
            resetAuthHeader();
            localStorage.clear()
            store.dispatch(logout())
        }
        else {
            toast.error(
                catchErrorMessage(
                    error?.response?.data?.message || error?.message || 'Something went wrong, please try again later.'
                ),
                {
                    position: toast.POSITION.TOP_CENTER,
                }
            );
        }
    },
};

const getRefreshToken = async () => {
    try {
        const refresh_token = localStorage.getItem('refresh_token')
        const response = await axios.post(`/api/v2/auth/refresh-token-admin`, {}, {headers: {Authorization: `Bearer ${refresh_token}`}});
        store.dispatch(setAccessToken({accessToken: response.data.data.access_token}));
        setAuthHeader(response?.data?.data?.access_token)
    }
    catch (err){
        resetAuthHeader();
        localStorage.clear()
        store.dispatch(logout())
    }
}

function ErrorFallback({error, resetErrorBoundary}) {
    return <GeneralErrorPage errorCode={500} errorMessage={error.message}/>;
}

function App() {
    const [showSplashScreen, setShowSplashScreen] = useState(true);
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    mutations: defaultQueryConfig,
                    queries: defaultQueryConfig,
                },
            })
    );

    useEffect(() => {
        setTimeout(() => {
            setShowSplashScreen(false);
        }, 1000);
    }, []);

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            <QueryClientProvider client={queryClient}>
                <PersistGate loading={null} persistor={persistor}>
                    <Provider store={store}>
                        {showSplashScreen ? (
                            <SplashScreenPage/>
                        ) : (
                            <BrowserRouter>
                                <ToastContainer
                                    position='top-center'
                                    style={{
                                        width: '500px',
                                    }}
                                    pauseOnHover
                                    autoClose={3000}
                                />
                                <RoutePage/>
                            </BrowserRouter>
                        )}
                    </Provider>
                </PersistGate>
            </QueryClientProvider>
        </ErrorBoundary>
    );
}

export default App;
