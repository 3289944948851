import { Link } from 'react-router-dom';
import { logout } from 'reducers/auth.reducer';
import { useDispatch, useSelector } from 'react-redux';
import localStorage from "redux-persist/es/storage";
export const Header = (props) => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  const { breadCumbs = [] } = props;

  const handleOnLogout = () => {
    dispatch(logout());
    localStorage.removeItem('client_token')
    localStorage.removeItem('menu')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('session_id')
    localStorage.removeItem('persist:APPLICATION_STATE');
  };

  return (
    <div className='flex justify-between mb-8'>
      <ul className='flex list-none items-center breadcrumb '>
        {breadCumbs.map((item, index) => {
          // if the first item, return the icon

          if (index === 0) {
            return (
              <li className='font-bold text-2xl text-green flex items-center'>
                <Link to={item?.path}>{item?.title}</Link>
              </li>
            );
          }

          return (
            <li className='font-medium text-green text-base'>
              <Link to={item?.path}>{item?.title}</Link>
            </li>
          );
        })}
      </ul>

      <div className='w-[450px]'>
        <div className='flex items-center justify-end'>
          <div className='relative pr-[60px]'>
            <a
              href='notifikasi.html'
              className='leading-tight w-14 h-14 relative text-green flex items-center justify-center border border-solid border-gray-1 text-left'
              type='button'
              id='drop-2'
            >
              <span className='icon-ico-notif text-2xl'></span>
              <div className='rounded-[50%] bg-red-1 text-white w-4 h-4 flex items-center justify-center text-[10px] absolute top-3 right-3'>
                3
              </div>
            </a>
          </div>
          <div className='dropdown relative'>
            <button
              className='leading-tight w-[280px] btn-arrow rounded-sm px-2 py-2 relative flex items-center border border-solid border-gray-1 text-left'
              type='button'
              id='drop-1'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              <div className='flex items-center'>
                <div className='w-10 rounded-sm'>
                  <img
                    src={auth.profile.profile_picture || '/img/default-user.jpg'}
                    className='max-w-full'
                    alt='User'
                  />
                </div>
                <div className='w-48 overflow-hidden pl-3'>
                  <div className='text-dark font-semibold overflow-hidden text-sm text-ellipsis whitespace-nowrap'>
                    {auth.profile.name}
                  </div>
                  <div className='text-gray text-sm '>{`${auth.profile.role.name} - ${auth.profile.role.code}`}</div>
                </div>
              </div>
            </button>
            <ul className='dropdown-menu min-w-max absolute border border-solid border-gray-1 hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded-sm mt-1 m-0 bg-clip-padding  w-[280px]'>
              <li onClick={handleOnLogout} className='cursor-pointer'>
                <span className=' dropdown-item text-sm py-2 px-4 block text-center w-full whitespace-nowrap bg-transparent font-medium'>
                  Log Out
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
