import {MainDashboardLayoutComponent} from "../../../components/main-layout/main.component";
import {PATH_CONSTANT} from "../../../config/path.constant";
import {InputComponent} from "../../../components/form/input.component";
import {useState} from "react";
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import {useRolePermissionList} from "../../../api/users/user-role/user-role.query.api";
import {useCreateUserRole} from "../../../api/users/user-role/user-role.mutation.api";
import {toast} from "react-toastify";

const BREAD_CRUMB_ITEMS = [
    {
        title: 'Users',
        path: PATH_CONSTANT.USER.USER_ROLE,
    },
    {
        title: 'Create User Role',
        path: PATH_CONSTANT.USER.USER_ROLE,
    },
];

export const UserRoleCreateComponent = () => {
    const navigate = useNavigate();
    const { mutateAsync: createUserRole, isLoading: isCreating } = useCreateUserRole();
    const { data: masterDataPermissions } = useRolePermissionList();
    const [show, setShow] = useState(true)
    const [child, setChild] = useState([])
    const [selectedIds, setSelectedIds] = useState([])
    const [activeAccess, setActiveAccess] = useState('')
    const [roleName, setRoleName] = useState('')

    const handleChange = (e) => {
        setRoleName(e?.target?.value)
    }

    const onSubmitHandler = async  () => {
        let body = {
            name : roleName,
            permission_ids: selectedIds,
        }
        console.log(body)

        await createUserRole(body)
        toast.success('User role created successfully', {
            autoClose: 2000,
        });
        navigate(PATH_CONSTANT.USER.USER_ROLE);
    }

    const changeActiveAccess = (item) => {
        setActiveAccess(item?.menu);
        setChild(item?.capabilities)
    }

    const checkedAllHandler = (e) => {
        let newSelectedIds = []
        const allItemIds = child.map(i => i?.id)
        const isChecked = e?.target?.checked
        if (!isChecked) {
            newSelectedIds = selectedIds.filter(i => !allItemIds.includes(i))
        } else {
            newSelectedIds = [...selectedIds, ...allItemIds]
        }
        setSelectedIds(newSelectedIds)
    }

    const checkedHandler = (e) => {
        let newIds = []
        let findIndex = _.findIndex(selectedIds, s => s === Number(e.target.name))
        if (findIndex === -1 && !isNaN(e.target.name)) {
            newIds = [...selectedIds, Number(e.target.name)]
        } else {
            newIds = _.filter(selectedIds, s => s !== Number(e.target.name))
        }
        setSelectedIds(newIds)
    }

    return (
        <MainDashboardLayoutComponent breadCumbs={BREAD_CRUMB_ITEMS}>
            <InputComponent
                name='role_name'
                value={roleName}
                onChange={handleChange}
                label='Role Name'
                placeholder='Input Nama Role'
            />
            <div
                className={"flex flex-col divide-y divide-gray-1 font-regular text-monstera border border-gray-1 rounded"}>
                <div className={"flex flex-row items-center justify-between p-4 bg-light-green rounded"}>
                    <div className={"flex flex-row space-x-3 items-center"}>
                        <p className={"text-sm capitalize"}>Access</p>
                    </div>
                    <div className={"flex flex-row items-center space-x-4"} onClick={() => setShow(!show)}>
                        {
                            show ? <span className='icon-ico-arrow-down'></span> :
                                <span className='icon-ico-arrow-up'></span>
                        }
                    </div>
                </div>
                <div className={`p-4 ${show ? 'block' : 'hidden'}`}>
                    <div className={'flex flex-row h-[28rem]'}>
                        <div className={"w-1/4 border-r border-gray-1 py-5 space-y-3 overflow-y-scroll"}>
                            {
                                !!masterDataPermissions?.data?.length &&
                                masterDataPermissions?.data.map((item, index) => (
                                    <div key={index}
                                         onClick={() => changeActiveAccess(item)}
                                         className={`flex flex-row items-center justify-between pl-2 py-2 cursor-pointer ${activeAccess === item.menu ? 'pl-2 mr-2 font-semibold bg-green-1 rounded-md' : ''}`}>
                                        <p className={"capitalize text-sm"}>{item?.menu}</p>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={"flex-1"}>
                            <div className={"grid grid-cols-3 gap-3 px-10 py-5"}>
                            {
                                    child.length > 0 &&
                                    <label
                                        className={"flex flex-row items-center space-x-5 border border-gray-1 h-8 rounded-md px-3 text-sm  hover:border-border-green hover:bg-green-status cursor-pointer"}>
                                        <input type={"checkbox"}
                                               className={'text-green-2 rounded-sm border-border-green'}
                                               checked={child.every(i => selectedIds.includes(i?.id))}
                                               onChange={checkedAllHandler}/>
                                        <p className={"capitalize"}>Select All</p>
                                    </label>
                                }
                                {
                                    child.map((item, index) =>
                                        <label
                                            key={index}
                                            className={`flex flex-row items-center space-x-5 border border-gray-1 h-8 rounded-md px-3 text-sm  ${item?.isDisabled ? 'bg-gray-200 opacity-80 cursor-not-allowed' : 'hover:border-border-green hover:bg-green-status cursor-pointer'}`}>
                                            <input type={'checkbox'} name={item?.id}
                                                   className={'text-green-2 rounded-sm border-border-green'}
                                                   checked={_.findIndex(selectedIds, s => s === item?.id) !== -1}
                                                   onChange={checkedHandler}/>
                                            <p className={"capitalize"}>{item?.name}</p>
                                        </label>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"flex flex-col items-center py-4"}>
                <button
                    className={"bg-green text-sm rounded-md text-white font-semibold px-10 py-2"}
                    onClick={onSubmitHandler}
                >
                    Save All Changes
                </button>
            </div>
        </MainDashboardLayoutComponent>
    )
}
