import qs from 'query-string';
import axios from 'api/api.config';
import {useQuery} from 'react-query';

export const USER_ADMIN_QUERY_KEY = {
    USER_ADMIN_LIST: 'USER_ADMIN_LIST',
    USER_ADMIN_DETAIL: 'USER_ADMIN_DETAIL',
    USER_ADMIN_MENU: 'USER_ADMIN_MENU',
};

export function useUserAdminList({filter, body}) {
    return useQuery([USER_ADMIN_QUERY_KEY.USER_ADMIN_LIST, {filter, body}, body], () =>
        axios.get(`/api/v1/admin/administrator?${qs.stringify(filter)}`).then((res) => res.data)
    );
}

export function useUserAdminMenu() {
    return useQuery([USER_ADMIN_QUERY_KEY.USER_ADMIN_MENU], () =>
        axios.get(`api/v1/admin/menu`).then((res) => res.data)
    );
}

export function useUserAdminDetail(roleId) {
    return useQuery(
        [USER_ADMIN_QUERY_KEY.USER_ADMIN_DETAIL, roleId],
        () => axios.get(`/api/v1/admin/administrator/detail/${roleId}`).then((res) => res.data)
    );
}
