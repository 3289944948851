import qs from 'query-string';
import axios from 'api/api.config';
import {useQuery} from 'react-query';

export const USER_ROLE_QUERY_KEY = {
    USER_ROLE_LIST: 'USER_ROLE_LIST',
    USER_ROLE_DETAIL: 'USER_ROLE_DETAIL',
    USER_ROLE_LIST_PERMISSION: 'USER_ROLE_LIST_PERMISSION',
};

export function useUserRoleList({filter, body}) {
    return useQuery([USER_ROLE_QUERY_KEY.USER_ROLE_LIST, {filter, body}, body], () =>
        axios.get(`/api/v1/admin/role?${qs.stringify(filter)}`).then((res) => res.data)
    );
}

export function useAllUserRole(filter) {
    return useQuery([USER_ROLE_QUERY_KEY.USER_ROLE_LIST, filter], () =>
        axios.get(`/api/v1/admin/role/all?${qs.stringify(filter)}`).then((res) => res.data)
    );
}

export function useUserRoleDetail(roleId) {
    return useQuery(
        [USER_ROLE_QUERY_KEY.USER_ROLE_DETAIL, roleId],
        () => axios.get(`/api/v1/admin/role/detail/${roleId}`).then((res) => res.data)
    );
}

export function useRolePermissionList() {
    return useQuery(
        [USER_ROLE_QUERY_KEY.USER_ROLE_DETAIL],
        () => axios.get(`/api/v1/admin/role/permissions`).then((res) => res.data)
    );
}
