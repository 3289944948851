import axios from 'api/api.config';
import { useMutation } from 'react-query';

export function useLogin() {
  return useMutation((values) => axios.post('/api/v2/auth/login-admin', values).then((res) => res.data));
}

export function useVerifyLogin() {
  return useMutation((values) => axios.post('/api/v2/auth/verify-login-admin', values).then((res) => res.data));
}

export function useRefreshToken() {
  return useMutation(() => axios.post('/api/v2/auth/refresh-token-admin').then((res) => res.data));
}

export function useClientToken() {
  return useMutation(() => axios.post('/api/v2/auth/client-token-admin').then((res) => res.data));
}

export function useForgotPassword() {
  return useMutation((values) => axios.post('/api/v1/auth/forgot-password-admin', values).then((res) => res.data));
}
