import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import InputCode from "./input-code";
import {ModalContainer} from "../../../components/modal/modal-container.component";
import {useGetAdminProfile} from "../../../api/auth/profile.api";
import {ButtonSubmit} from "../../../components/button/button-submit.component";
import {useVerifyLogin} from "../../../api/auth/login.api";
import {useNavigate, useSearchParams} from "react-router-dom";
import {setAccessToken, setBasicProfile} from "../../../reducers/auth.reducer";
import {setAuthHeader} from "../../../api/api.config";

const Verification = ({recaptchaRef, isShow, onCloseVerification}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams();
    const redirectPath = searchParams.get('redirect') || '/';
    const session_id = localStorage.getItem('session_id')
    const [errorOTP, setErrorOTP] = useState('')
    const [OTP, setOTP] = useState('')
    const otpLength = 4
    const {mutateAsync: getAdminProfile} = useGetAdminProfile();
    const {mutate: verifyLogin} = useVerifyLogin();

    useEffect(() => {
        if (!isShow) {
            setOTP('')
        }
    }, [isShow]);

    const closeModalHandler = () => {
        recaptchaRef?.current?.reset()
        onCloseVerification()
    }

    const verificationHandler = () => {
        if (OTP.length < otpLength) {
            setErrorOTP('Please Complete Input OTP')
        } else {
            setErrorOTP('')
            const body = {
                otp: Number(OTP),
                session_id
            }
            verifyLogin(body, {
                onSuccess: async (response) => {
                    setAuthHeader(response.data.access_token)
                    dispatch(
                        setAccessToken({
                            accessToken: response.data.access_token,
                            refreshToken: response.data.refresh_token
                        })
                    );
                    await localStorage.setItem('refresh_token', response?.data?.refresh_token)
                    const profile = await getAdminProfile();
                    dispatch(setBasicProfile(profile.data));
                    navigate(redirectPath);
                },
                onError(error) {
                    switch (error?.response?.data?.message) {
                        case 'invalid-token':
                        case 'invalid-otp-session':
                        case 'otp-mismatch':
                            onCloseVerification()
                            break
                    }
                }
            })
        }
    }

    return (
        <>
            <ModalContainer
                visible={isShow}
                children={
                    <div className={"relative"}>
                        <div className={"flex flex-col justify-center w-80 h-auto p-5 bg-white rounded-xl"}>
                            <div className={"flex flex-col space-y-6 justify-center items-center"}>
                                <p className={'text-sm font-semibold mb-4'}>Input OTP code from your email</p>
                                <InputCode cols={otpLength} value={OTP} onChange={(e) => setOTP(e)}/>
                                {errorOTP && <p className={"font-semibold text-red-600 italic"}>{errorOTP}</p>}
                                <ButtonSubmit
                                    className='bg-green w-full hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
                                    title='Submit'
                                    onClick={verificationHandler}
                                />
                            </div>
                        </div>
                        <button type='button' className='absolute top-2 right-3' onClick={closeModalHandler}>
                            <span className='icon-ico-close text-xl text-gray-2'></span>
                        </button>
                    </div>
                }
            />
        </>
    )
}

export default Verification
