import {useMutation, useQueryClient} from "react-query";
import axios from "../../api.config";
import {USER_ADMIN_QUERY_KEY} from "./admin.query.api";

export function useCreateUserAdmin() {
    const queryClient = useQueryClient();
    return useMutation((body) => axios.post(`/api/v1/admin/administrator/create`, body).then((res) => res.data), {
        onSuccess: () => {
            queryClient.removeQueries([USER_ADMIN_QUERY_KEY.USER_ADMIN_DETAIL]);
            queryClient.removeQueries([USER_ADMIN_QUERY_KEY.USER_ADMIN_LIST]);
        },
    });
}

export function useEditUserAdmin() {
    const queryClient = useQueryClient();
    return useMutation(({ id, body }) => axios.put(`/api/v1/admin/administrator/update/${id}`, body).then((res) => res.data), {
        onSuccess: () => {
            queryClient.removeQueries([USER_ADMIN_QUERY_KEY.USER_ADMIN_DETAIL]);
            queryClient.removeQueries([USER_ADMIN_QUERY_KEY.USER_ADMIN_LIST]);
        },
    });
}

export function useDeleteUserAdminDetail() {
    const queryClient = useQueryClient();
    return useMutation((roleId) => axios.delete(`/api/v1/admin/administrator/delete/${roleId}`).then((res) => res.data),
        {
            onSuccess: () => {
                queryClient.removeQueries([USER_ADMIN_QUERY_KEY.USER_ADMIN_DETAIL]);
                queryClient.removeQueries([USER_ADMIN_QUERY_KEY.USER_ADMIN_LIST]);
            },
        }
    );
}
