import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Formik} from 'formik';

import {ButtonSubmit} from 'components/button/button-submit.component';
import {InputComponent, InputPasswordComponent} from 'components/form/input.component';
import {LoginValidationSchema} from 'config/form/validation-schema/auth/login-validation.schema';
import axios, {setAuthHeader} from 'api/api.config';
import ReCAPTCHA from "react-google-recaptcha";
import {toast} from "react-toastify";
import {catchErrorMessage} from "../../../ui-utils/string.utils";
import * as bcrypt from "bcryptjs-react";
import Verification from "./verification";

const formInitialValues = {
    email: '',
    password: '',
};

export const LoginComponent = () => {
    const recaptchaRef = useRef()
    const [showPassword, setShowPassword] = React.useState(false);
    const [errorCaptcha, setErrorCaptcha] = useState('')
    const [captcha, setCaptcha] = useState(null)
    const [showVerification, setShowVerification] = useState(false)
    const client_token = localStorage.getItem('client_token')

    useEffect(() => {
        if (!client_token) {
            getClientToken()
        }
    }, [client_token]);

    const getClientToken = async () => {
        try {
            const hashed = bcrypt.hashSync(process.env.REACT_APP_DASHBOARD_CLIENT_ID, 10)
            const clientToken = await axios.post(`/api/v2/auth/client-token-admin`, {}, {headers: {'x-client-id': hashed}});
            await localStorage.setItem('client_token', clientToken?.data.data.token)
        } catch (error) {
            toast.error(
                catchErrorMessage(
                    error?.response?.data?.message || error?.message || 'Something went wrong, please try again later.'
                ),
                {
                    position: toast.POSITION.TOP_CENTER,
                }
            );
        }
    }

    const handleOnFormSubmit = async (formData, {resetForm}) => {
        try {
            const loginResponse = await axios.post(`/api/v2/auth/login-admin`, {...formData},
                {
                    headers: {
                        'x-captcha-token': captcha,
                        Authorization: `Bearer ${client_token}`
                    },
                })
            setAuthHeader(client_token)
            await localStorage.setItem('session_id', loginResponse.data.data.session_id)
            setShowVerification(true)
        } catch (error) {
            switch (error?.response?.data?.message) {
                case 'invalid-token':
                    setShowVerification(false)
                    setCaptcha(null)
                    if (recaptchaRef.current)recaptchaRef.current.reset();
                    getClientToken()
                    break
                case 'invalid-credential':
                    setShowVerification(false)
                    setCaptcha(null)
                    if (recaptchaRef.current)recaptchaRef.current.reset();
                    resetForm()
                    toast.error(catchErrorMessage(`${error?.response?.data?.message}, please try again.`), {position: toast.POSITION.TOP_CENTER});
                    break
                default:
                    toast.error(
                        catchErrorMessage(
                            error?.response?.data?.message || error?.message || 'Something went wrong, please try again later.'
                        ),
                        {
                            position: toast.POSITION.TOP_CENTER,
                        }
                    );
                    break
            }
        }
    };

    return (
        <body className='bg-login'>
        <div
            className="absolute left-0 bottom-0 w-full h-full bg-no-repeat bg-left-bottom"
            style={{backgroundImage: "url('/img/leaf-login.svg')"}}
        />
        <div className='flex justify-center h-screen items-center relative'>
            <div className='w-[400px]'>
                <div className='block text-center mb-12'>
                    <img src='/img/logo.svg' className='max-w-full inline-block' alt='Logo'/>
                </div>

                <div className='w-full bg-white rounded-sm p-4 pb-14'>
                    <Formik
                        validationSchema={LoginValidationSchema}
                        initialValues={formInitialValues}
                        onSubmit={handleOnFormSubmit}
                    >
                        {({errors, touched, handleChange, handleBlur, handleSubmit, values}) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className='text-2xl font-bold text-green text-center pt-2 pb-6'>Login</div>
                                    <InputComponent
                                        label='Email / Username'
                                        placeholder='Input your email or username'
                                        name='email'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.email && errors.email}
                                        value={values.email}
                                    />

                                    <InputPasswordComponent
                                        name='password'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.password && errors.password}
                                        value={values.password}
                                        onToggleShow={() => setShowPassword(!showPassword)}
                                        showPassword={showPassword}
                                        label='Password'
                                        placeholder='Input your password'
                                    />

                                    <ReCAPTCHA ref={recaptchaRef}
                                               sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                               onChange={(e) => setCaptcha(e)}
                                               onExpired={() => setCaptcha('')}/>
                                    {errorCaptcha &&
                                        <p className={"font-semibold text-red-600 italic"}>{errorCaptcha}</p>}

                                    <Link
                                        to='/auth/forgot-password'
                                        className='text-sm font-bold inline-block text-green mb-3 hover:text-dark-green'
                                    >
                                        Forgot Password
                                    </Link>

                                    <ButtonSubmit
                                        type='submit'
                                        disabled={!LoginValidationSchema.isValidSync(values) || !captcha}
                                        className='bg-green w-full hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
                                        title='Login'
                                    />
                                </form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
        <Verification recaptchaRef={recaptchaRef} isShow={showVerification}
                      onCloseVerification={() => {
                          if (recaptchaRef.current) recaptchaRef.current.reset();
                          setCaptcha(null)
                          setShowVerification(false)
                      }}/>
        </body>
    );
};
