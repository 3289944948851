// The environment to use when building the project
const CONFIG = {
  endpoint: {
    APP_ENDPOINT: process.env.REACT_APP_URL,
    API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  },
  key: {
    GOOGLE_MAP_API: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  },
  timeout: {
    REQUEST: process.env.REACT_APP_MAX_TIMEOUT_REQUEST,
  },
  captcha : {
    SITE_KEY : process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  }
};

export default CONFIG;
