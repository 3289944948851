import { Formik } from 'formik';
import { useMemo, useRef, useState } from 'react';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { InputComponent, InputPasswordComponent } from 'components/form/input.component';
import { Select } from 'components/form/select.component';
import { toast } from 'react-toastify';
import { useAllStoreList } from 'api/store/store-list/store-list.query.api';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { CreateUserValidationSchema } from 'config/form/users/create-user/create-user-validation.schema';
import { useCreateUser } from 'api/users/create/create-user.mutation';
import { BounceLoading } from 'components/loader/bounce.loading';
import { useNavigate } from 'react-router-dom';
import { useUploadSingleImage, UPLOAD_IMAGE_CONSTANT } from 'api/upload/upload-single-image.api';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { PATH_CONSTANT } from 'config/path.constant';
import { generateRandomPassword } from 'ui-utils/string.utils';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Users',
    path: PATH_CONSTANT.USER.USER_LIST,
  },
  {
    title: 'Create User',
    path: PATH_CONSTANT.USER.USER_CREATE,
  },
];

const formInitialValue = {
  store_id: null,
  name: '',
  email: '',
  phone: '',
  password: '',
  profile_picture: null,
  join_date: '',
};

export const UserCreateComponent = () => {
  const formikRef = useRef();
  const navigate = useNavigate();

  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { data: storeData, isLoading } = useAllStoreList();
  const { mutateAsync: createUser, isLoading: isCreating } = useCreateUser();
  const { mutateAsync: uploadUserProfilePicture, isLoading: isUploading } = useUploadSingleImage({
    path: UPLOAD_IMAGE_CONSTANT.DIRECTORY_PATH.PROFILE_PICTUTE,
    type: UPLOAD_IMAGE_CONSTANT.TYPE.COMPRESSED,
  });

  const [selectedStoreDetails, setSelectedStoreDetails] = useState(null);
  const [selectedFileBuffer, setSelectedFileBuffer] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('/img/default-user.jpg');

  const storeOptions = useMemo(() => {
    if (storeData) {
      return storeData?.data?.map((item) => ({
        id: item.id,
        name: item.name,
      }));
    }
    return [];
  }, [storeData]);

  const handleOnFormSubmit = async (formValues) => {
    try {
      let profilePictureURL = null;
      if (selectedFileBuffer) {
        const formData = new FormData();
        formData.append('image', selectedFileBuffer);
        const response = await uploadUserProfilePicture(formData);
        profilePictureURL = response?.data?.url;
      }
      const payload = {
        ...formValues,
        profile_picture: profilePictureURL,
        phone: `+62${formValues.phone}`,
      };
      if (!formValues.phone) payload.phone = '';

      await createUser(payload);
      toast.success('User created successfully', {
        autoClose: 2000,
      });
      navigate(PATH_CONSTANT.USER.USER_LIST);
    } catch (error) {}
  };

  const handleOnUploadFileChange = (e) => {
    //chedck file size with maximum 5mb and type, only accept image
    if (e.target.files[0].size > 5000000) {
      toast('File size is too large. Maximum 5mb is allowed');
      return;
    }
    if (!e.target.files[0].type.includes('image')) {
      toast('Only image file is allowed');
      return;
    }
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setSelectedFileBuffer(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const isDisabledAllForm = isCreating || isUploading;

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      <div className='flex'>
        {isLoading ? (
          <div className='h-[70vh] flex w-full items-center text-center'>
            <BounceLoading color='#5E755A' />
          </div>
        ) : (
          <Formik
            innerRef={formikRef}
            initialValues={formInitialValue}
            onSubmit={handleOnFormSubmit}
            validationSchema={CreateUserValidationSchema}
          >
            {({ errors, values, handleChange, handleBlur, touched, setFieldValue }) => {
              return (
                <>
                  <div class='w-64 flex justify-center'>
                    <div class='w-auto'>
                      <img
                        src={imagePreviewUrl}
                        class='w-40 h-40 rounded-full border-8 border-solid border-green-2'
                        alt='Preview'
                      />
                      <div class='mt-7 flex justify-center'>
                        <input
                          type='file'
                          id='upload'
                          class='hidden'
                          onChange={handleOnUploadFileChange}
                          accept='image/*'
                        />
                        <label
                          for='upload'
                          class='bg-green w-28 inline-block text-center hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-3 cursor-pointer'
                        >
                          <span class='icon-ico-upload mr-2'></span>
                          <span class='text-sm'>Upload</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class='w-1/2'>
                    <InputComponent
                      disabled={isDisabledAllForm}
                      name='name'
                      value={values.name}
                      error={touched.name && errors.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label='Nama'
                      placeholder='Input Nama'
                    />
                    <InputComponent
                      disabled={isDisabledAllForm}
                      name='email'
                      value={values.email}
                      error={touched.email && errors.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label='Email'
                      placeholder='Input Email'
                    />
                    <InputComponent
                      disabled={isDisabledAllForm}
                      numberOnly={true}
                      key='phone'
                      name='phone'
                      value={values.phone}
                      error={touched.phone && errors.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      containerClass='form-input relative mb-3'
                      label='Nomor Telepon'
                      prefix='+62'
                      inputClass='w-full border rounded-sm outline-none border-solid border-gray-1 py-2.5 pl-16 pr-4 bg-white text-sm text-dark placeholder:text-gray-2 focus:border-green focus:text-green focus:bg-gray-focus transition duration-100 ease-in-out'
                      type='tel'
                      placeholder='Nomor Telepon'
                    />
                    <InputComponent
                      disabled={isDisabledAllForm}
                      name='join_date'
                      value={values.join_date}
                      error={touched.join_date && errors.join_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label='Tanggal Bergabung'
                      type='date'
                      placeholder='Input Nama'
                    />

                    <Select
                      disabled={isDisabledAllForm}
                      name='store_id'
                      onChange={(e) => {
                        handleChange(e);
                        setSelectedStoreDetails(storeData?.data?.find((item) => item.id === +e.target.value));
                      }}
                      onBlur={handleBlur}
                      value={values.store_id}
                      options={storeOptions}
                      error={touched.store_id && errors.store_id}
                      placeholder='Pilih Store'
                      containerClassName='form-input  relative mb-3'
                      label='Store Penempatan'
                    />
                    <div class='relative mb-3'>
                      {!selectedStoreDetails ? (
                        <div class='w-full min-h-[150px] border border-solid border-gray-1 rounded-sm flex justify-center items-center'>
                          <div class='text-sm text-dark'>Tidak Ada Data Terpilih</div>
                        </div>
                      ) : (
                        <div class='w-full border border-solid border-gray-1 rounded-sm p-4'>
                          <div class='flex items-center justify-between mb-3'>
                            <div class='text-sm font-semibold text-green'>{selectedStoreDetails?.name}</div>
                            <div class='text-sm font-medium italic text-dark'>{selectedStoreDetails?.city?.name}</div>
                          </div>
                          <p class='text-sm'>{selectedStoreDetails?.address}</p>
                        </div>
                      )}
                    </div>
                    <InputPasswordComponent
                      disabled={isDisabledAllForm}
                      name='password'
                      value={values.password}
                      error={touched.password && errors.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label='Password'
                      onToggleShow={() => setShowPassword(!showPassword)}
                      showPassword={showPassword}
                      placeholder='Input Password'
                    />
                    <ButtonSubmit
                      loading={isCreating | isUploading}
                      onClick={() => {
                        setFieldValue('password', generateRandomPassword(10));
                      }}
                      className='bg-green hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
                    >
                      Generate Password
                    </ButtonSubmit>

                    <div class='py-14 relative flex justify-center'>
                      <ButtonSubmit
                        loading={isCreating | isUploading}
                        disabled={!CreateUserValidationSchema.isValidSync(values)}
                        onClick={() => {
                          setModalConfirmation(true);
                        }}
                        className='bg-green hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
                      >
                        <span class='icon-ico-apply mr-3'></span> Create User
                      </ButtonSubmit>
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        )}
      </div>
      <ModalConfirmation
        description='Apakah anda yakin ingin membuat user baru?'
        title='Create User'
        imageIcon='/img/info.svg'
        textConfirm='Create'
        loading={isCreating}
        visible={modalConfirmation}
        onConfirm={() => {
          formikRef?.current?.handleSubmit();
        }}
        onClose={() => setModalConfirmation(false)}
      />
    </MainDashboardLayoutComponent>
  );
};
