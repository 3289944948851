import qs from 'query-string';
import axios from 'api/api.config';
import { useMutation } from 'react-query';

export const UPLOAD_IMAGE_CONSTANT = {
  DIRECTORY_PATH: {
    PROFILE_PICTUTE: 'PROFILE_PICTURE',
    STORE_IMAGE: 'STORE_IMAGE',
    GENERAL_SETTING_ICON: 'GENERAL_SETTING_ICON',
    PRODUCT_IMAGE: 'PRODUCT_IMAGE',
    SHARE_INFO: 'SHARE_INFO',
    SKILL_DEVELOPMENT: 'SKILL_DEVELOPMENT',
    ADMIN_PICTURE: 'ADMIN_PICTURE',
  },
  TYPE: {
    COMPRESSED: 'COMPRESSED',
    ORIGINAL: 'ORIGINAL',
  },
};

export function useUploadSingleImage({ path, type }) {
  return useMutation((body) =>
    axios.post(`/api/v1/admin/upload/single/image?${qs.stringify({ path, type })}`, body).then((res) => res.data)
  );
}
export function useUploadSingleFile({ path, type }) {
  return useMutation((body) =>
    axios.post(`/api/v1/admin/upload/single/file?${qs.stringify({ path, type })}`, body).then((res) => res.data)
  );
}
