import {MainDashboardLayoutComponent} from "../../../components/main-layout/main.component";
import {useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {BounceLoading} from "../../../components/loader/bounce.loading";
import {Formik} from "formik";
import {InputComponent, InputPasswordComponent} from "../../../components/form/input.component";
import {ButtonSubmit} from "../../../components/button/button-submit.component";
import {generateRandomPassword} from "../../../ui-utils/string.utils";
import {useCreateUserAdmin} from "../../../api/users/admin/admin.mutation.api";
import {CreateUserAdminValidationSchema} from "../../../config/form/users/create-admin/create-admin-validation.schema";
import {UPLOAD_IMAGE_CONSTANT, useUploadSingleImage} from "../../../api/upload/upload-single-image.api";
import {Select} from "../../../components/form/select.component";
import {useAllUserRole} from "../../../api/users/user-role/user-role.query.api";
import {ModalConfirmation} from "../../../components/modal/moda.confirmation.component";
import {toast} from "react-toastify";
import {PATH_CONSTANT} from "../../../config/path.constant";

const BREAD_CRUMB_ITEMS = [
    {
        title: 'Users',
        path: '/users/admin',
    },
    {
        title: 'Create Admin',
        path: '/users/admin/create',
    },
];

const formInitialValue = {
    role_id: null,
    name: null,
    email: null,
    phone: null,
    password: null,
    profile_picture: null,
    address: null,
};

export const CreateAdminComponent = () => {
    const formikRef = useRef();
    const navigate = useNavigate();

    const [modalConfirmation, setModalConfirmation] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [selectedFileBuffer, setSelectedFileBuffer] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('/img/default-user.jpg');

    const { data: roleData, isLoading } = useAllUserRole();
    const { mutateAsync: createUserAdmin, isLoading: isCreating } = useCreateUserAdmin();
    const { mutateAsync: uploadUserProfilePicture, isLoading: isUploading } = useUploadSingleImage({
        path: UPLOAD_IMAGE_CONSTANT.DIRECTORY_PATH.PROFILE_PICTUTE,
        type: UPLOAD_IMAGE_CONSTANT.TYPE.COMPRESSED,
    });

    const roleOptions = useMemo(() => {
        if (roleData) {
            return roleData?.data?.map((item) => ({
                id: item.id,
                name: item.name,
            }));
        }
        return [];
    }, [roleData]);

    const handleOnFormSubmit = async (formValues) => {
        try {
            let profilePictureURL = null;
            if (selectedFileBuffer) {
                const formData = new FormData();
                formData.append('image', selectedFileBuffer);
                const response = await uploadUserProfilePicture(formData);
                profilePictureURL = response?.data?.url;
            }
            const payload = {
                ...formValues,
                profile_picture: profilePictureURL,
                phone: `+62${formValues.phone}`,
            };

            if (!formValues.phone) payload.phone = '';

            await createUserAdmin(payload);
            toast.success('User Admin created successfully', {
                autoClose: 2000,
            });
            navigate(PATH_CONSTANT.USER.USER_ADMIN);
        } catch (error) {}
    };

    const handleOnUploadFileChange = (e) => {
        if (e.target.files[0].size > 5000000) {
            toast('File size is too large. Maximum 5mb is allowed');
            return;
        }
        if (!e.target.files[0].type.includes('image')) {
            toast('Only image file is allowed');
            return;
        }
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            setSelectedFileBuffer(file);
            setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const isDisabledAllForm = isCreating || isUploading;

    return(
        <MainDashboardLayoutComponent breadCumbs={BREAD_CRUMB_ITEMS}>
            <div className='flex'>
                {isLoading ? (
                    <div className='h-[70vh] flex w-full items-center text-center'>
                        <BounceLoading color='#5E755A'/>
                    </div>
                ) : (
                    <Formik
                        innerRef={formikRef}
                        initialValues={formInitialValue}
                        onSubmit={handleOnFormSubmit}
                        validationSchema={CreateUserAdminValidationSchema}
                    >
                        {({errors, values, handleChange, handleBlur, touched, setFieldValue}) => {
                            return (
                                <>
                                    <div className='w-64 flex justify-center'>
                                        <div className='w-auto'>
                                            <img
                                                src={imagePreviewUrl}
                                                className='w-40 h-40 rounded-full border-8 border-solid border-green-2'
                                                alt='Preview'
                                            />
                                            <div className='mt-7 flex justify-center'>
                                                <input
                                                    type='file'
                                                    id='upload'
                                                    className='hidden'
                                                    onChange={handleOnUploadFileChange}
                                                    accept='image/*'
                                                />
                                                <label
                                                    htmlFor='upload'
                                                    className='bg-green w-28 inline-block text-center hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-3 cursor-pointer'
                                                >
                                                    <span className='icon-ico-upload mr-2'></span>
                                                    <span className='text-sm'>Upload</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-1/2'>
                                        <InputComponent
                                            disabled={isDisabledAllForm}
                                            name='name'
                                            value={values.name}
                                            error={touched.name && errors.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label='Nama'
                                            placeholder='Input Nama'
                                        />
                                        <InputComponent
                                            disabled={isDisabledAllForm}
                                            name='email'
                                            value={values.email}
                                            error={touched.email && errors.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label='Email'
                                            placeholder='Input Email'
                                        />
                                        <InputComponent
                                            disabled={isDisabledAllForm}
                                            numberOnly={true}
                                            key='phone'
                                            name='phone'
                                            value={values.phone}
                                            error={touched.phone && errors.phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            containerClass='form-input relative mb-3'
                                            label='Nomor Telepon'
                                            prefix='+62'
                                            inputClass='w-full border rounded-sm outline-none border-solid border-gray-1 py-2.5 pl-16 pr-4 bg-white text-sm text-dark placeholder:text-gray-2 focus:border-green focus:text-green focus:bg-gray-focus transition duration-100 ease-in-out'
                                            type='tel'
                                            placeholder='Nomor Telepon'
                                        />

                                        <Select
                                            disabled={isDisabledAllForm}
                                            name='role_id'
                                            onChange={(e) => handleChange(e)}
                                            onBlur={handleBlur}
                                            value={values.role_id}
                                            options={roleOptions}
                                            error={touched.role_id && errors.role_id}
                                            placeholder='Pilih Role'
                                            containerClassName='form-input  relative mb-3'
                                            label='Admin Role'
                                        />
                                        <InputPasswordComponent
                                            disabled={isDisabledAllForm}
                                            name='password'
                                            value={values.password}
                                            error={touched.password && errors.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label='Password'
                                            onToggleShow={() => setShowPassword(!showPassword)}
                                            showPassword={showPassword}
                                            placeholder='Input Password'
                                        />
                                        <ButtonSubmit
                                            loading={isCreating | isUploading}
                                            onClick={() => {
                                                setFieldValue('password', generateRandomPassword(10));
                                            }}
                                            className='bg-green hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
                                        >
                                            Generate Password
                                        </ButtonSubmit>

                                        <div className='py-14 relative flex justify-center'>
                                            <ButtonSubmit
                                                loading={isCreating | isUploading}
                                                disabled={!CreateUserAdminValidationSchema.isValidSync(values)}
                                                onClick={() => {
                                                    setModalConfirmation(true);
                                                }}
                                                className='bg-green hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
                                            >
                                                <span className='icon-ico-apply mr-3'></span> Create User Admin
                                            </ButtonSubmit>
                                        </div>
                                    </div>
                                </>
                            );
                        }}
                    </Formik>
                )}
            </div>
            <ModalConfirmation
                description='Apakah anda yakin ingin membuat user admin baru?'
                title='Create User Admin'
                imageIcon='/img/info.svg'
                textConfirm='Create'
                loading={isCreating}
                visible={modalConfirmation}
                onConfirm={() => {
                    formikRef?.current?.handleSubmit();
                }}
                onClose={() => setModalConfirmation(false)}
            />
        </MainDashboardLayoutComponent>
    )
}
